export const achievementData = {
    bio : "Test Bio",
    achievements : [
      /*  {
            id : 1,
            title : 'Certificate 01',
            details : 'Updating',
            date : 'Date',
            field : 'Automation',
            image : 'Image Link'
        },
        {
            id : 2,
            title : 'Certificate 02',
            details : 'Updating',
            date : 'Date',
            field : 'Automation',
            image : 'Image Link'
        },
        {
            id : 3,
            title : 'Certificate 03',
            details : 'Updating',
            date : 'Date',
            field : 'Automation',
            image : 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        }*/

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/