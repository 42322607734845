export const educationData = [
    {
        id: 1,
        institution: 'H/ Yatala Tissa Primary School',
        course: 'Primary Education',
        startYear: '2006',
        endYear: '2010'
    },
    {
        id: 2,
        institution: 'H/ Debarawewa National Collage',
        course: 'Secondary Education',
        startYear: '2011',
        endYear: '2019'
    },
    {
        id: 3,
        institution: 'Sri Lanka Institute of Information Technology (SLIIT)',
        course: 'Undergratuated Degree of SE',
        startYear: '2022',
        endYear: 'Present'
    },
]