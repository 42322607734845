import resume from '../assets/pdf/resume.pdf'
import profile from '../assets/svg/header/profile.png'

export const headerData = {
    name: 'Shanelka Pramuditha',
    title: "Undergratuated Student",
    desciption:"I am undergratuated student at SLIIT with a passion for IT. I have honed my skills in a my industry. I am driven to constantly learn and grow, and am eager to apply my knowledge to new challenges.",
    //image: 'https://avatars.githubusercontent.com/u/74038575?v=4',
    image: profile,
    resumePdf: resume
}
