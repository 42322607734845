export const socialsData = {
    github: 'https://github.com/ShanelkaPramuditha',
    facebook: 'https://www.facebook.com/ShanelkaPramuditha',
    linkedIn: 'https://www.linkedin.com/in/ShanelkaPramuditha',
    instagram: 'https://www.instagram.com/ShanelkaPramuditha',
    codepen: 'https://codepen.io/ShanelkaPramuditha',
    twitter: 'https://twitter.com/Shanelkapramu',
    //reddit: 'https://www.reddit.com/user/',
    //blogger: 'https://www.blogger.com/',
    //medium: 'https://medium.com/@',
    stackOverflow: 'https://stackoverflow.com/users/16930024/shanelka-pramuditha',
    gitlab: 'https://gitlab.com/ShanelkaPramuditha',
    //youtube: 'https://youtube.com/'
}