export const experienceData = [
    {
        id: 1,
        company: 'Orel IT',
        jobtitle: 'Associate Image Processor (AIP)',
        startYear: '2021',
        endYear: ''
    },
    {
        id: 2,
        company: 'Paranagama Associates (PVT) Ltd',
        jobtitle: 'Computer and System Operator',
        startYear: '2022',
        endYear: ''
    },
    /*{
        id: 3,
        company: 'Wonka Industries',
        jobtitle: 'Marketing',
        startYear: '2020',
        endYear: 'Present'
    },*/
]