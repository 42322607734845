/* eslint-disable */
import google from '../assets/svg/testimonials/google.svg'
import cisco from '../assets/svg/testimonials/cisco.svg'
import ycs from '../assets/svg/testimonials/ycs.svg'
import microsoft from '../assets/svg/testimonials/microsoft.svg'
import art from '../assets/svg/testimonials/art.svg'
import boy3 from '../assets/svg/testimonials/boy3.svg'



export const testimonialsData = [
    {
        id: 1,
        name: 'Google Digital Garage',
        title: 'The Fundamentals of Digital Marketing',
        text: "Build up my digital marketing knowledge with Google. I intend to use that knowledge for the future.",
        image: google
    },
    {
        id: 2,
        name: 'Cisco Networking Academy',
        title: 'Introduction to Cybersecurity',
        text: "Upgraded my knowledge about Global implications of cyber threats, Cisco’s approach to threat detection and defense, Impact of cyber-attacks on industries,... more.",
        image: cisco
    },
    {
        id: 3,
        name: 'Young Computer Scientist',
        title: '7th National Level School ICT Championship 14th Young Computer Scientist Competition',
        text: "Prizes received from competitions participated in during school life.",
        image: ycs
    },
    {
        id: 4,
        name: 'Microsoft',
        title: 'School Educational Software Group Project 2011',
        text: "Upgraded my knowledge with Microsoft Sri Lanka under the Partners in Learning Programme.",
        image: microsoft
    },
    {
        id: 5,
        name: 'Microsoft',
        title: 'School Educational Software Group Project 2012',
        text: "Upgraded my knowledge with Microsoft Sri Lanka under the Partners in Learning Programme.",
        image: microsoft
    },
    {
        id: 6,
        name: 'Department of  Cultural Affairs',
        title: 'State Children\'s Art Festival',
        text: "More things gained from interest in art during school days.",
        image: art
    }
]

